import Mixins from "../../Mixins.js";

export default {
  name: "AddDeposit",
  mixins: [Mixins],
  data() {
    return {
      property: {
        animation: {
          addDeposit: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        bpjsChartOfAccountId: "",
        zakatChartOfAccountId: "",
        pajakChartOfAccountId: "",
        depositId: null,
        productCategoryId: "",
        depositCode: "",
        depositName: "",
        currencyId: "",
        isMudharabahMuqayyadah: false,
        depositMinimum: "",
        depositMaximum: "",
        nominalNotTaxable: "",
        tax: 0,
        zakat: 0,
        bpjs: 0,
        depositBreakPenalty: "",
        zakatAccountNumber: "",
        infakAccountNumber: "",
        depositTimeTypeId: "",
        depositProfitShareTypeId: "",
        depositAkadTypeId: "",
        statusId: "",
        chartOfAccountId: "",
      },
      options: {
        mataUang: [],
        jenisWaktu: [],
        pembukuanBagiHasil: [],
        nisbah: [],
        kodeProduk: [],
        parameterCoaList: [],
      },
    };
  },
  methods: {
    automationSkipper() {
      this.$store.dispatch("SET_IDENTIFIER_DEPOSIT_ID", "153");
      // this.MIXINS_METHOD_getRefferenceDepositChartOfAccount();
    },
    async getReferenceCurrency() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              currencyName: "",
              page: 0,
            },
            url: "m-currency",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const value = i.currencyId;
            const text = i.currencyName;
            this.options.mataUang.push({ value, text });
          });
        }
      } catch (error) {}
    },
    async getReferenceDepositTimeType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "deposit-time-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.depositTimeTypeId;
            const text = i.depositTimeTypeName;
            this.options.jenisWaktu.push({ value, text });
          });
        }
      } catch (error) {}
    },
    async getReferenceDepositAkadType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "deposit-akad-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.depositAkadTypeId;
            const text = i.depositAkadTypeName;
            this.options.nisbah.push({ value, text });
          });
        }
      } catch (error) {}
    },
    async getReferenceDepositProfitShareTypeData() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "deposit-profit-share-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.profitShareTypeId;
            const text = i.profitShareTypeName;
            this.options.pembukuanBagiHasil.push({ value, text });
          });
        }
      } catch (error) {}
    },
    getReferenceProductCategoryId() {
      this.$store.dispatch("GET_REFERENCE_PRODUCT_CATEGORY").then((resp) => {
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            if (
              i.productCategoryId ===
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_PRODUCT_CATEGORY_DEPOSITO
            ) {
              this.dataForm.productCategoryId = i.productCategoryCode;
            }
          });
        }
      });
    },
    async addDeposit() {
      const payload = {
        productCategoryId: this.dataForm.productCategoryId,
        depositCode: this.dataForm.depositCode,
        depositName: this.dataForm.depositName,
        currencyId: this.dataForm.currencyId,
        isMudharabahMuqayyadah: this.dataForm.isMudharabahMuqayyadah,
        depositMinimum: this.dataForm.depositMinimum,
        depositMaximum: this.dataForm.depositMaximum,
        nominalNotTaxable: this.dataForm.nominalNotTaxable,
        tax: this.dataForm.tax,
        zakat: this.dataForm.zakat,
        depositBreakPenalty: this.dataForm.depositBreakPenalty,
        zakatAccountNumber: this.dataForm.zakatAccountNumber,
        infakAccountNumber: this.dataForm.infakAccountNumber,
        depositTimeTypeId: this.dataForm.depositTimeTypeId,
        depositProfitShareTypeId: this.dataForm.depositProfitShareTypeId,
        depositAkadTypeId: this.dataForm.depositAkadTypeId,
        statusId: "1",
        chartOfAccountId: this.dataForm.chartOfAccountId,
        zakatChartOfAccountId: this.dataForm.zakatChartOfAccountId,
        pajakChartOfAccountId: this.dataForm.pajakChartOfAccountId,
        bpjs: this.dataForm.bpjs,
        bpjsChartOfAccountId: this.dataForm.bpjsChartOfAccountId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.addDeposit.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "transaction",
                reqUrl: "deposit",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  this.$store.dispatch(
                    "SET_IDENTIFIER_DEPOSIT_ID",
                    resp.data.data.depositId
                  );
                  // toastBuefy.$on("close", () => {
                  //   this.MIXINS_METHOD_getRefferenceDepositChartOfAccount();
                  // });
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.addDeposit.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    getParameterCoa() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "chart-of-account",
          params: {
            description: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.content.map((index) => {
              const text = `${index.chartOfAccountCode} - ${index.description}`;
              const value = index.chartOfAccountId;
              this.options.parameterCoaList.push({ text, value });
            });
          }
        });
    },
  },
  mounted() {
    // this.automationSkipper();
    this.getReferenceCurrency();
    this.getReferenceDepositTimeType();
    this.getReferenceDepositAkadType();
    this.getReferenceDepositProfitShareTypeData();
    this.getReferenceProductCategoryId();
    this.getParameterCoa();
  },
};
