export default {
  data() {
    return {
      MIXINS_DATA: {
        identifier: {
          depositId: this.$store.getters["get_identifier_deposit_id"],
        },
      },
    };
  },
  computed: {
    mixin_data_identifier_deposit_id() {
      return this.$store.getters["get_identifier_deposit_id"];
    },
    // mixin_data_options_deposit_chart_of_account_deposit_nisbah_chart_of_account() {
    //   return this.$store.getters[
    //     "get_options_deposit_chart_of_account_deposit_nisbah_chart_of_account"
    //   ];
    // },
  },

  methods: {
    // async MIXINS_METHOD_getRefferenceDepositChartOfAccount() {
    //   if (window.navigator.onLine === false) {
    //     this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
    //     return;
    //   } else {
    //     try {
    //       const resp = await this.$store.dispatch(
    //         "GET_REFERENCE_FROM_MASTER_TRANSACTION",
    //         {
    //           url: "deposit-chart-of-account",
    //           params: {
    //             depositId: this.$store.getters["get_identifier_deposit_id"],
    //             page: 0,
    //           },
    //         }
    //       );
    //       var depositChartOfAccount = [];
    //       depositChartOfAccount = [{ value: "", text: "-- Pilih --" }];
    //       this.$store.dispatch(
    //         "SET_OPTIONS_DEPOSIT_CHART_OF_ACCOUNT_DEPOSIT_NISBAH_CHART_OF_ACCOUNT",
    //         depositChartOfAccount
    //       );
    //       if (resp.data.code === "SUCCESS") {
    //         Promise.all(
    //           resp.data.data.content.map((index) => {
    //             const value = index.depositChartOfAccountId;
    //             const text = `${index.mchartOfAccount.description} - ${index.mproductLedger.productLedgerName}`;
    //             depositChartOfAccount.push({ value, text });
    //           })
    //         ).then(() => {
    //           this.$store.dispatch(
    //             "SET_OPTIONS_DEPOSIT_CHART_OF_ACCOUNT_DEPOSIT_NISBAH_CHART_OF_ACCOUNT",
    //             depositChartOfAccount
    //           );
    //         });
    //       }
    //     } catch (error) {}
    //   }
    // },
  },
};
